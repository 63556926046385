/*
.App {
  text-align: center;
  z-index: 100;
  width: 100%;
  position: absolute; 
}
*/
.App-header {
  background: #FFF;
  z-index: 0;
}
.App-header.fade {
  transform: translate(100%, 0); transition: transform 1000ms ease-in;
}

.App-logo {
  height: 40vmin;
  /* pointer-events: none; */
  padding: 20px;
  cursor: pointer;
  border: solid 1px #EAEAEA;
  border-radius: 50%;
  background-color: #FFFFFF;
  position: fixed;
  margin: 100px auto;
  z-index: 100;
  display: block;
  left: 40%;
}
@media (max-width: 991.98px) {
  .App-logo {
    left: calc(100vw - 320px);
  }
}
.App-logo:hover {
  border: solid 1px #333333;
  box-shadow: 0 5px 3px #CACACA;

  margin-top: 95px;
  transition: margin-top 50ms ease-out;
}
.App-logo.selected {
  margin-top: 95px;
  border: solid 1px #CACACA;
  border-right: none;
  border-bottom: none;
  border-radius: 50%;
  box-shadow: inset 5px 5px 3px #CACACA;
}
.App-logo.clicked {
  border: solid 1px #CACACA;
  border-radius: 50%;
  box-shadow: none;
}
.App-logo.fade {
  opacity : 0; transition: opacity 1s;
}

/*
.instr.fade {
  opacity : 0; transition: opacity 1s;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}
*/

.App-header {
  /* background-color: #282c34; */
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  /* color: white; */
}

.App-link {
  color: #61dafb;
}

.navbar-nav {
  text-align: left;
  margin-left: 0;
}
.navbar-nav .nav-link {
  cursor: pointer;
  padding-left: 10px;
}
#tour_card {
  margin: 0;
}

/* Audio Player & Track Listing */
#audio_player {
  padding: 0;
  margin: 10px 0;
  text-align: left;
  position: sticky;
  top: 70px;
  height: 40px;
}
#audio_player a {
  cursor: pointer;
}
#audio_list {
  font-size: .75em;
}
#audio_list li {
  border-bottom: solid 1px #333333;
}

.react-player audio {
  height: revert;
}

.ku-embed {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}