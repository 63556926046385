/* ************************************************************** */
/* ***** offcanvas.css ****************************************** */
/* ************************************************************** */

@font-face { font-family: GothicThirteenStd; src: url('../font/GothicThirteenStd.otf'); }  */

html,
body {
  overflow-x: hidden; /* Prevent scroll on narrow devices */
}

/* 
body {
  padding-bottom: 56px;
}
*/

/* .offcanvas-collapse { */
.navbar-collapse {
  position: fixed;
  top: 0;
  /* bottom: 56px; Height of navbar */
  bottom: 0;
  left: 100%;
  width: 100%;
  padding: 0;
  overflow-y: auto;
  visibility: hidden;
  background-color: #000000;
  color: #ffffff;
  transition: visibility .3s ease-in-out, -webkit-transform .3s ease-in-out;
  transition: transform .3s ease-in-out, visibility .3s ease-in-out;
  transition: transform .3s ease-in-out, visibility .3s ease-in-out, -webkit-transform .3s ease-in-out;
  font-family: GothicThirteenStd;
  font-size: 22px;
}

.navbar-collapse.show {
  visibility: visible;

  -moz-transform: translateX(-400px); /* Firefox */
  -ms-transform: translateX(-400px); /* Internet Explorer */
  -o-transform: translateX(-400px);
  -webkit-transform: translateX(-400px);
  transform: translateX(-400px);
}
@media (max-width: 991.98px) {
  .navbar-collapse.show {
    visibility: visible;

    -moz-transform: translateX(-100%); /* Firefox */
    -ms-transform: translateX(-100%); /* Internet Explorer */
    -o-transform: translateX(-100%);
    -webkit-transform: translateX(-100%);
    transform: translateX(-100%);
  }
}

.navbar-toggler {
  background-color: #000000;
}
.navbar-toggler-icon {
  border: solid 1px #CACACA;
  border-radius: 5px;
}

.nav-underline .nav-link {
  padding-top: .75rem;
  padding-bottom: .75rem;
  font-size: .875rem;
  color: #6c757d;
}

.nav-underline .nav-link:hover {
  color: #007bff;
}

.nav-underline .active {
  font-weight: 500;
  color: #343a40;
}

.nav-tabs .nav-link.active {
  background-color: #333333;
}

/*
.text-white-50 { color: rgba(255, 255, 255, .5); }

.bg-purple { background-color: #6f42c1; }

.lh-100 { line-height: 1; }
.lh-125 { line-height: 1.25; }
.lh-150 { line-height: 1.5; }
*/

#offcanvas_navigation {
  border: solid 1px #000000;
  border-bottom: none;
  margin: 0 15px;
  /* padding: 10px 0 0 0; */
  padding: 0;
  max-width: 400px;
}

#tour-title-row {
  /* position: sticky;
  top: 0; */
  background-color: #000000;
  z-index: 1000;
  text-align: right;
  margin: 0;
  padding: 10px 0 0 0;
}
#tour-title-row p {
  font-size: 18px;
  margin-bottom: 0;
}
@media (min-width: 576px) {
  #tour-title-row p {
    font-size: 22px;
  }
}

#tab-row {
  position: sticky;
  top: 0;
  background-color: #000000;
  padding: 10px 0 0 10px;
  margin: 0;
  z-index: 1000;
}

#profile-tab {
  border-left: none;
}
/* ************************************************************** */

.navbar-toggler {
  position: absolute;
  right: 10px; bottom: 10px;
  z-index: 1001;
}

.tab-pane-instr {
  max-width: 400px;
  margin: 10px;
  font-size: .8em;
  border: solid 1px #CACACA;
  padding: 5px 15px;
}

#bottom-right {
  position: absolute;
  bottom: 0; right: 0;
  max-width: 200px;
}

#items {
  max-width: 400px;
  padding: 10px;
}

img.item_thumb {
  border: solid 1px #333333;
  background-color: #EAEAEA;
  width: 100px;
  height: 100px;
  margin: 5px;
  float: left;
}